import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import  apiReducer  from './apiSlice'


const persistConfig = {
  key: 'api',
  storage
}

const persistedReducer = persistReducer(persistConfig, apiReducer)

export const store = configureStore({
  reducer : persistedReducer,
  middleware: [thunk]
})
export const persistor = persistStore(store)