import Table from 'react-bootstrap/Table'
// import Pagination from 'react-bootstrap/Pagination'
import { Button, Container, Form, Row, Col } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import ModalPasswordChangeAgency from '@/Components/Modal/PasswordChangeAgency'

const AdminsUsersAgenciesGet = () => {
  const navigation = useNavigate()
  const params = useParams();
  const [userInfo, setUserInfo] = useState({
    _id: '',
    userName: '',
    userId: '',
    kiccMid: '',
    phoneNumber: '',
  })
  const [modalPasswordChange, setModalPasswordChange] = useState(false)

  const handleModifyBtnPressed = () => {
    const payload = {
      userName: userInfo.userName,
      kiccMid: userInfo.kiccMid
    }
    if (userInfo.userName.length < 2)
      return alert('가맹점명을 2자리 이상 입력해 주세요.')
    if (userInfo.kiccMid.length < 5)
      return alert('KICC MID를 4자리 이상 입력해 주세요.')

    api.admins.users.agencies.patchAgency({
      _id: userInfo._id,
      userId: userInfo.userId,
      userName: userInfo.userName,
      kiccMid: userInfo.kiccMid
    }).then((res: any) => {
      if (!res.error.ok) {
        alert(res.error.message)
      } else {
        alert(`정보변경이 완료되었습니다.`)
        return navigation(0)
      }
    })

  }

  useEffect(() => {
    api.admins.users.agencies.getAgency({ _id: params._id }).then((res: any) => {
      console.info(res)
      if (!res.error.ok) {
        alert("정보를 가져오는데 실패하였습니다")
        navigation(-1)
      } else {
        setUserInfo(res.result.agency)
      }
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [])


  return (
    <>
      <ModalPasswordChangeAgency
        show={modalPasswordChange}
        setShow={setModalPasswordChange}
        prop={userInfo}
        setResult={() => { }}
      />
      <Container fluid>
        <Row>
          <p className="text-secondary">
            가맹점 수정
          </p>
        </Row>
        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="id" className="mb-3">
              <Form.Label>고유키</Form.Label>
              <Form.Control type="text" value={userInfo._id} disabled />
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>아이디(이메일)</Form.Label>
              <Form.Control type="text" value={userInfo.userId} disabled />
              <Form.Text>
                아이디 변경은 불가합니다
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>KICCMID</Form.Label>
              <Form.Control type="text" value={userInfo.kiccMid}
                onChange={(e) => setUserInfo({ ...userInfo, kiccMid: e.target.value.trim() })}
              />
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>이름</Form.Label>
              <Form.Control type="text" value={userInfo.userName}
                onChange={(e) => setUserInfo({ ...userInfo, userName: e.target.value.trim() })}
              />
            </Form.Group>
            <Row>
              <Col className="d-flex flex-column pb-4">
                <span className='text-muted'>비밀번호</span>
                <Row>
                  <Col className="col-6">
                    <Button className="btn-block" variant='danger' onClick={() => setModalPasswordChange(true)}>
                      비밀번호변경
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <Button type="submit" variant="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    return window.history.back()
                  }}
                >
                  뒤로가기
                </Button>
              </Col>
              <Col className="col-6">
                <Button
                  variant="warning"
                  type="submit"
                  className="float-end"
                  onClick={(e) => {
                    e.preventDefault()
                    return handleModifyBtnPressed()
                  }}
                >
                  변경사항 저장
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </>
  )
}

export default AdminsUsersAgenciesGet