import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const Main = () => {
  const navigation = useNavigate()
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      if (sessionStorage.getItem("userType") == "admin") {
        return navigation('/admins/users/customers/list')
      } else {
        return navigation('/agencies/branches/codes/list')
      }
    } else {
      return navigation('/login')
    }
  })
  return (
    <>
    </>
  )

}

export default Main