import { Modal, Button, Container, Row, Col, Form, Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import ModalPasswordChangeAgency from './PasswordChangeAgency';

const ModalSearchAgencies = ({ show }: { show: boolean }) => {
  const [results, setResults] = useState([{
    name: 'test',
    ipAddress: '127.123.123.123',
    port: 9100,
    agencyName: '테스트가맹점',
  }]);

  if (!show) return (<></>)
  return (
    <>
      <div className="vh-100 vw-100" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        transition: 'all 0.3s ease-in-out',
      }}>
        <div
          className="modal "
          style={{ display: 'block', }}
        >
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>가맹점 검색</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Container>
                <Form.Group className="mb-3">
                  <Form.Label>
                    검색어를 입력하세요
                  </Form.Label>
                  <Row className="justify-content-middle">
                    <Col className="align-self-center">
                      <Form.Control></Form.Control>
                    </Col>
                    <Col>
                      <Button variant="primary">검색</Button>
                    </Col>
                  </Row>
                  <Form.Text>이름, IP, Port로 검색 가능합니다</Form.Text>
                </Form.Group>
                <Table bordered hover className="table-sm">
                  <thead>
                    <tr>
                      <th>이름</th>
                      <th>IP</th>
                      <th>Port</th>
                      <th>가맹점명</th>
                      <th>선택</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      results.map((printer: any) => {
                        return (
                          <tr>
                            <td>{printer.name}</td>
                            <td>{printer.ipAddress}</td>
                            <td>{printer.port}</td>
                            <td>{printer.agencyName}</td>
                            <td>
                              <Button
                                size="sm"
                              // onClick={() => { navigation(`/admins/hosts/printers/${printer._id}`) }}
                              >상세</Button></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Container>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary">Close</Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    </>
  )
}
export default ModalSearchAgencies