import { createSlice } from '@reduxjs/toolkit';

export const apiSlice = createSlice({
  name: 'api',
  initialState: {
    value: 5,
  },
  reducers: {
    increment: state => {
      state.value += 1;
    }

  },
});

export const { increment } = apiSlice.actions;
export default apiSlice.reducer;