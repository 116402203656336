import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container, Row, Modal, Badge } from 'react-bootstrap'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import ModalSearchAgencies from '@/Components/Modal/SearchAgencies'

const AgenciesBranchesCodesList = () => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [resultCount, setResultCount] = useState(0)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [searchCount, setSearchCount] = useState(0)

  const fetch = (params: any) => {
    console.info(`fetch: page: ${params.page}`)
    console.info(`fetch: query: ${params.query}`)
    api.agencies.branches.codes.listCodes({
      page: params.page,
      query: params.query,
    }).then((res: any) => {
      console.info(res)
      setResults(res.result.codes)
      setSearchCount(res.result.total)
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    return window.location.href = (`/agencies/branches/codes/list?page=${page}&query=${query}`)
  }

  useEffect(() => {
    const values = queryString.parse(search)
    const queryPage = values.page ? Number(values.page) : 1
    const queryQuery = values.query ? String(values.query) : ''
    console.info(`useEffect[page]: ${queryPage}`)
    console.info(`useEffect[query]: ${queryQuery}`)
    setPage(prevPage => queryPage)
    setQuery(prevQuery => queryQuery)
    api.agencies.branches.codes.countAllCodes().then((res: any) => {
      setResultCount(res.result.count)
      fetch({ page: queryPage, query: queryQuery })
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [])


  return (
    <>
      <ModalSearchAgencies
        show={false}
      />
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            코드목록
          </p>
        </Row>
        <Row className="bg-white mb-4">
          <Row className="mt-2 mb-2">
            <Col>
              전체 코드: <b>{resultCount.toLocaleString()}</b>건 / 검색결과: <b>{searchCount.toLocaleString()}</b>건
            </Col>
          </Row>
          <Row className="mb-2">
            <Row className="mb-2">
              <Col sm={12} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="검색어를 입력하세요(이름/코드)"
                  size="sm"
                  value={query}
                  onChange={(e) => { setQuery(e.target.value) }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    return window.location.href = (`/agencies/branches/codes/list?page=1&query=${query}`)
                  }}
                >검색</Button>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className="bg-white pt-2 pb-2 ">
          <Col>
          </Col>
          <Col
            className="d-flex justify-content-end"

          >
            <Button
              size="sm"
              className="align-self-end"
              onClick={() => {
                return window.location.href = ('/agencies/branches/codes/create')
              }}
            >
              신규코드 생성
            </Button>

          </Col>
        </Row>
        <Row className="bg-white">

          <Table bordered hover responsive="lg" className="table-sm">
            <thead>
              <tr>
                <th>이름</th>
                <th>코드</th>
                <th>가맹점명</th>
                <th>프린터IP</th>
                <th>모바일출력</th>
                <th>요금(흑백)</th>
                <th>요금(컬러)</th>
                <th>생성일</th>
                <th>수정</th>
              </tr>
            </thead>
            <tbody>
              {
                results.map((code: any) => {
                  return (
                    <tr>
                      <td>{code.name || ""}</td>
                      <td>{code.code || ""}</td>
                      <td>
                        <Button size="sm" variant='secondary'
                          onClick={() => window.location.href = (`/agencies/users/agencies/${code.agencyId}`)}
                        >
                          {code.agencyName || ""}
                        </Button>
                      </td>
                      <td>
                        <Button variant='secondary' size="sm"
                          onClick={() => window.location.href = (`/agencies/hosts/printers/${code.printerId}`)}
                        >
                          {code.ipAddress || ""}
                        </Button>
                      </td>
                      <td>{code.mobilePrinting ? <Badge className="bg-success">ON</Badge> : <Badge className="bg-secondary text-light">OFF</Badge> || "에러"}</td>
                      <td>{code.monoPricing["a4"] || ""}</td>
                      <td>{code.colorPricing["a4"] || ""}</td>
                      <td>{dayjs(code.createdAt).format('YY. MM. DD.')}</td>
                      <td>
                        <Button
                          size="sm"
                          variant='warning'
                          onClick={() => { navigation(`/agencies/branches/codes/${code._id}`) }}
                        >수정</Button></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Row>
        <Row className="bg-white pb-4">
          <Col>
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={searchCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AgenciesBranchesCodesList