import { Card, Modal, Button, Container, Row, Col, Form, Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import api from '@/api'

const ModalSearchCustomers = ({ show, setShow, setResult }: { show: boolean, setShow: any, setResult: any }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([]);

  const handleSearchBtn = () => {
    if (!query) return alert('검색어를 입력하세요')
    api.admins.users.customers.listCustomers({
      query,
      per_page: 10,
      page: 1,
    }).then((res: any) => {
      setResults(res.result.customers)
    }).catch((e) => {
      console.error(e);
      alert('서버오류가 발생하였습니다')
    })

  }

  const handleCloseBtn = () => {
    return setShow(false)
  }

  if (!show) return (<></>)
  return (
    <>
      <div className="vh-100 vw-100" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        transition: 'all 0.3s ease-in-out',
      }}>
        <div
          className="modal "
          style={{ display: 'block', }}
        >
          <Modal.Dialog>
            <Modal.Header closeButton onHide={handleCloseBtn}>
              <Modal.Title>고객 검색</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Container>
                <Form.Group className="mb-3">
                  <Form.Label>
                    검색어를 입력하세요
                  </Form.Label>
                  <Row className="justify-content-middle">
                    <Col className="align-self-center col-9">
                      <Form.Control
                        defaultValue={query}
                        onChange={(e) => {
                          setQuery(e.target.value.trim())
                        }}
                      />
                    </Col>
                    <Col className="col-3">
                      <Button variant="primary"
                        onClick={handleSearchBtn}
                      >검색</Button>
                    </Col>
                  </Row>
                  <Form.Text>이름, 이메일, 전화번호로 검색 가능합니다</Form.Text>
                </Form.Group>
                {
                  results.map((customer: any) => {
                    return (
                      <Card style={{}} className="mb-4">
                        <Card.Body>
                          <Card.Title>{customer.userName}</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">{customer.userId}</Card.Subtitle>
                          <Card.Subtitle className="mb-2 text-muted">{customer.phoneNumber}</Card.Subtitle>
                          <Card.Text>
                          </Card.Text>
                          <Button
                            onClick={() => {
                              setResult(customer)
                              setShow(false)
                            }}
                          >
                            선택
                          </Button>
                        </Card.Body>
                      </Card>
                    )
                  })
                }
              </Container>
            </Modal.Body>

            <Modal.Footer>
              {/* <Button variant="secondary">Close</Button>
              <Button variant="primary">Save changes</Button> */}
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    </>
  )
}
export default ModalSearchCustomers 