// import Pagination from 'react-bootstrap/Pagination'
import { Button, Form, Container, Row, Col } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { isIPv4 } from 'is-ip'
import api from '@/api'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const AgenciesHostsPrintersGet = () => {
  const navigation = useNavigate()
  const params = useParams();
  const [printerInfo, setPrinterInfo] = useState({
    _id: '',
    name: '',
    ipAddress: '',
    port: '',
    createdAt: '',
  })


  const handleModifyBtnPressed = () => {
    if (printerInfo.name.length < 2)
      return alert('프린터 이름을 2자리 이상 입력해주세요.')

    if (!isIPv4(printerInfo.ipAddress))
      return alert(`IP주소가 올바르지 않습니다.`)

    if (isNaN(parseInt(printerInfo.port)))
      return alert(`포트번호가 올바르지 않습니다.`)
    if (parseInt(printerInfo.port) < 1 || parseInt(printerInfo.port) > 65535)
      return alert(`포트번호가 올바르지 않습니다.`)


    const payload = {
      _id: params._id,
      name: printerInfo.name,
      ipAddress: printerInfo.ipAddress,
      port: printerInfo.port,
    }
    return api.agencies.hosts.printers.patchPrinter(payload).then((res: any) => {
      if (!res.error.ok) {
        console.error(res)
        return alert("프린터 수정에 실패하였습니다")
      } else {
        alert("프린터 수정에 성공하였습니다")
        return window.location.reload();
      }
    }).catch((e) => {
      console.error(e)
      return alert("서버오류가 발생하였습니다")
    })
  }

  useEffect(() => {
    api.agencies.hosts.printers.getPrinter({ _id: params._id }).then((res: any) => {
      if (!res.error.ok) {
        alert("정보를 가져오는데 실패하였습니다")
        navigation(-1)
      } else {
        setPrinterInfo(res.result.printer)
      }
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            프린터 수정
          </p>
        </Row>
        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="id" className="mb-3">
              <Form.Label>생성일</Form.Label>
              <Form.Control type="text" value={dayjs(printerInfo.createdAt).format('YYYY-MM-DD HH:mm:ss')} disabled />
              <Form.Text className="text-muted">
                생성일은 변경 불가합니다.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>이름</Form.Label>
              <Form.Control type="text" value={printerInfo.name}
                onChange={(e) => setPrinterInfo({ ...printerInfo, name: e.target.value })}
              />
              <Form.Label className='text-muted'>
                프린터 이름은 2자리 이상 입력해주세요.
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>IP 주소</Form.Label>
              <Form.Control type="text" value={printerInfo.ipAddress}
                onChange={(e) => setPrinterInfo({ ...printerInfo, ipAddress: e.target.value.trim() })}
              />
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>Port</Form.Label>
              <Form.Control type="text" value={printerInfo.port}
                onChange={(e) => setPrinterInfo({ ...printerInfo, port: e.target.value.trim() })}
              />
            </Form.Group>
            <Row>
              <Col>
                <Button type="submit" variant="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    return window.history.back()
                  }}
                >
                  뒤로가기
                </Button>

              </Col>
              <Col>
                <Button
                  variant='warning'
                  type="submit"
                  className="float-end"
                  onClick={(e) => {
                    e.preventDefault()
                    return handleModifyBtnPressed()
                  }}
                >
                  프린터 수정
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </>
  )
}

export default AgenciesHostsPrintersGet 