import axios, { AxiosRequestConfig } from 'axios'
import backofficeApi from '@/api/backoffice'
import agencyApi from '@/api/agency'

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.NODE_ENV ?
    `https://api.gongd-dev.co.kr/` : `https://api-gongd-dev.co.kr/`
}
const axiosInstance = axios.create(axiosConfig)

try {
  const token = sessionStorage.getItem("token")
  axios.defaults.headers.common['Authorization'] = `${token}`
  axiosInstance.defaults.headers.common['Authorization'] = `${token}`
  axiosInstance.interceptors.response.use((response: any) => {
    console.info(`[API] ${response.config.method} ${response.config.url} ${response.status} ${response.statusText}`)
    return response;
  }, (error: any) => {
    console.error(`[API] ${error.config.method} ${error.config.url} ${error.response.status} ${error.response.statusText}`)
    window.location.href = "/login"
    return Promise.reject(error)
  })
} catch (e) {
  console.error(e)
}

export default {
  config: {
    setToken: (token: string) => {
      sessionStorage.setItem("token", token)
      axios.defaults.headers.common['Authorization'] = `${token}`
      axiosInstance.defaults.headers.common['Authorization'] = `${token}`
    },
    setUserType: (type: string) => {
      sessionStorage.setItem('userType', type);
      return;
    },
    emptySessionStorage: () => {
      sessionStorage.clear();
    }
  },
  common: {
    sessions: {
      getSessionToken: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          url: '/web-common/sessions/token',
          method: 'post',
          data: {
            userId: params.userId,
            password: params.password,
            userType: params.userType
          }
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      )
    }
  },
  agencies: agencyApi,
  admins: backofficeApi,
}