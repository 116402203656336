import { Card, Modal, Button, Container, Row, Col, Form, Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import api from '@/api'

const ModalPasswordChangeAgency = ({ show, setShow, setResult, prop }: { show: boolean, setShow: any, setResult: any, prop: any }) => {
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const handleCloseBtn = () => {
    return setShow(false)
  }

  const handleBtnPressed = () => {
    if (password.length < 8) return alert('비밀번호는 10자 이상 입력해주세요.')
    if (password !== passwordRepeat) return alert('비밀번호와 비밀번호 확인이 일치하지 않습니다.')

    return api.admins.users.agencies.changePassword({
      _id: prop._id,
      password,
    }).then((res: any) => {
      if (!res.error.ok) {
        alert(res.error.message);
        return;
      } else {
        alert('비밀번호가 변경되었습니다.')
        setShow(false)
      }


    }).catch((e) => {
      console.error(e)
      alert('비밀번호 변경에 실패하였습니다.')
    })
  }


  if (!show) return (<></>)
  return (
    <>
      <div className="vh-100 vw-100" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        transition: 'all 0.3s ease-in-out',
      }}>
        <div
          className="modal "
          style={{ display: 'block', }}
        >
          <Modal.Dialog>
            <Modal.Header closeButton onHide={handleCloseBtn}>
              <Modal.Title>가맹점 비밀번호 변경</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Container>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      가맹점명 (ID)
                    </Form.Label>
                    <Form.Control defaultValue={`${prop.userName} (${prop.userId})`} type="text" placeholder="" disabled />
                  </Form.Group>


                  <Form.Group className="mb-3">
                    <Form.Label>
                      비밀번호
                    </Form.Label>
                    <Form.Control type="password" placeholder="비밀번호" onChange={(e) => setPassword(e.target.value)} />
                    <Form.Text className="text-muted">
                      비밀번호는 8자 이상 입력해주세요.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-0">
                    <Form.Label>
                      비밀번호확인
                    </Form.Label>
                    <Form.Control type="password" placeholder="비밀번호" onChange={(e) => setPasswordRepeat(e.target.value)} />
                    <Form.Text className="text-muted">
                      한번 더 입력하세요
                    </Form.Text>
                  </Form.Group>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary"
                onClick={() => handleBtnPressed()}
              >비밀번호 변경</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    </>
  )
}
export default ModalPasswordChangeAgency 