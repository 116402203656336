import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import { Button, Col, Container, Row, Modal, Badge, Alert, Image } from 'react-bootstrap'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import ModalSearchAgencies from '@/Components/Modal/SearchAgencies'



const AdminsImagesList = () => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [resultCount, setResultCount] = useState(0)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([{ imageURL: 'https://via.placeholder.com/500?text=loading', linkURL: '', adminInfo: { userName: '' } }])
  const [searchCount, setSearchCount] = useState(0)
  const [file, setFile] = useState('')
  const [linkURL, setLinkURL] = useState('')
  const [isUploadProgress, setIsUploadProgress] = useState(false)

  const fetch = (params: any) => {
    api.admins.images.listImages({
      page: params.page,
      query: params.query,
    }).then((res: any) => {
      setResults(res.result.images)
      setSearchCount(res.result.total)
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }

  useEffect(() => {
    const values = queryString.parse(search)
    const queryPage = values.page ? Number(values.page) : 1
    const queryQuery = values.query ? String(values.query) : ''
    setPage(prevPage => queryPage)
    setQuery(prevQuery => queryQuery)
    fetch({})
  }, [])

  const handleRegisterBtnPressed = () => {
    setIsUploadProgress(true)
    return api.admins.images.getUploadURL(undefined).then((response: any) => {
      if (!response?.error?.ok || !response?.result?.uploadURL)
        throw Error(response?.error?.message || "서버오류가 발생하였습니다")
      const uploadURL = response?.result?.uploadURL
      console.info(response?.result?.uploadURL)
      const data = new FormData()
      data.append('file', file)
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      return axios.post(
        uploadURL,
        data,
        config
      )
    }).then((response) => {
      if (!response?.data?.success)
        throw Error(response?.data?.messages[0] || "서버오류가 발생하였습니다")
      const imageURL = response?.data?.result.variants[0]

      // console.info(response.data);
      return api.admins.images.createImage({
        imageURL: imageURL,
        linkURL: linkURL
      });
    }).then((response: any) => {
      if (!response?.error?.ok)
        throw Error(response?.error?.message || "서버오류가 발생하였습니다")
      alert("등록되었습니다")
      window.location.reload()
    }).catch((e) => {
      if (e instanceof Error) {
        alert(e.message)
      }

    }).finally(() => {
      setIsUploadProgress(false)
    });

  }


  return (
    <>
      <ModalSearchAgencies
        show={false}
      />
      <Container fluid>
        {/* <!-- 신규등록 --> */}
        <Row className="bg-white mt-4 mb-4 pt-4 pb-4 border">
          <Row>
            <Col>
              <h5>신규등록</h5>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>이미지 업로드</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={(e: any) => {
                const file = e.target.files[0]
                console.info(file)
                setFile(file)
              }}
            />
            <Form.Text className="text-muted">
              이미지(JPG/PNG/GIF) 파일만 업로드 가능합니다. (최대 10MB, 512*512px 정사각 이미지 권장)
            </Form.Text>
          </Form.Group>
          <Form.Group className='mt-2'>
            <Form.Label>링크주소</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setLinkURL(e.target.value)}
              placeholder="http(s):// 를 포함한 링크를 입력하세요"
            />
            <Form.Text className="text-muted">
              이미지 클릭시 이동할 링크주소를 입력하세요. 올바른 링크가 입력되지 않은 경우 오류가 발생할 수 있습니다
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-2"
              onClick={handleRegisterBtnPressed}
            >
              {
                isUploadProgress ? (
                  <Spinner animation="border" role="status">
                  </Spinner>
                ) : "등록"
              }
            </Button>
          </Form.Group>
          <Form.Text className="text-muted">
            신규등록 완료 즉시 앱에 반영됩니다.
          </Form.Text>
        </Row>
        {/* <!-- 신규등록 끝 --> */}
        <Row className="bg-white mt-4 mb-4 pt-4 pb-4 border">
          <Row>
            <Col>
              <h5>현재광고</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Image src={results[0]?.imageURL} width={200} height={200} thumbnail />
            </Col>
            <Col>
              <Row>
                링크
              </Row>
              <Row>
                <a href={results[0]?.linkURL}>
                  {results[0]?.linkURL}
                </a>
              </Row>
            </Col>
          </Row>
        </Row>
        {/* <!-- 신규등록 끝 --> */}

        <Row className="bg-white border pt-4 pb-4 mb-4">
          <Row>
            <Col>
              <h5>광고이미지이력 (최근10건)</h5>
            </Col>
          </Row>
          <Row className="">
            <Table bordered hover responsive="lg" className="table-sm">
              <thead>
                <tr>
                  <th>등록일</th>
                  <th>이미지</th>
                  <th>링크</th>
                  <th>등록인</th>
                </tr>
              </thead>
              <tbody>
                {
                  results.map((image: any) => {
                    return (
                      <tr>
                        <td>
                          {dayjs(image.createdAt).format('YYYY-MM-DD HH:mm:ss') || "알수없음"}

                        </td>
                        <td>
                          <Image src={image.imageURL} width={200} height={200} thumbnail />
                        </td>
                        <td>
                          {image.linkURL ?
                            <>
                              <a target='_blank' href={image.linkURL}>바로가기</a>
                            </> :
                            "없음"
                          }
                        </td>
                        <td>
                          {image.adminInfo.userName || "알수없음"}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            <Row className="">
              <Col>
                {/* <Pagination
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={searchCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                /> */}
              </Col>
            </Row>
          </Row>
        </Row>



      </Container>
    </>
  )
}

export default AdminsImagesList