import Table from 'react-bootstrap/Table'
import { Button, Container, Form, Row, Col } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

const AdminsUsersAgenciesCreate = () => {
  const navigation = useNavigate()
  const params = useParams();

  const [userInfo, setUserInfo] = useState({
    userId: '',
    password: '',
    passwordConfirm: '',
    userName: '',
    kiccMid: '',
  })

  const handleBtnClicked = () => {
    if (userInfo.userId.length < 5)
      return alert('아이디를 5자리 이상 입력해주세요.')
    if (userInfo.password.length < 8)
      return alert('비밀번호를 8자리 이상 입력해주세요.')
    if (userInfo.password != userInfo.passwordConfirm)
      return alert('비밀번호와 비밀번호확인이 일치하지 않습니다.')
    if (userInfo.userName.length < 2)
      return alert('가맹점명을 2자리 이상 입력해 주세요.')
    if (userInfo.kiccMid.length < 5)
      return alert('KICC MID를 4자리 이상 입력해 주세요.')


    return api.admins.users.agencies.createAgency(
      userInfo
    ).then((res: any) => {
      if (!res.error.ok) {
        return alert(res.error.message)
      }
      else {
        alert('생성되었습니다');
        return navigation('/admins/users/agencies/list');
      }

    }).catch((e) => {
      return alert(e.mesage)
    })

  }

  useEffect(() => {
    console.info(userInfo)
  }, [userInfo])


  return (
    <>
      <Container fluid>
        <Row>
          <p className="text-secondary">
            가맹점 생성
          </p>
        </Row>
        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>아이디</Form.Label>
              <Form.Control
                type="text"
                value={userInfo.userId}
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    userId: e.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '').replace(/ /, '').trim()
                  })
                }} />
              <Form.Text>
                추후 아이디 변경 불가합니다. 신중히 입력해주세요.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="password" className="mb-3">
              <Form.Label>비밀번호</Form.Label>
              <Form.Control type="password"
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    password: e.target.value
                  })
                }}
              />
            </Form.Group>

            <Form.Group controlId="passwordConfirm" className="mb-3">
              <Form.Label>비밀번호 확인</Form.Label>
              <Form.Control type="password"
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    passwordConfirm: e.target.value
                  })
                }}
              />
              <Form.Text>
                비밀번호를 한번 더 입력해주세요.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>가맹점명</Form.Label>
              <Form.Control type="text"
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    userName: e.target.value.trim()
                  })
                }}
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>KICC MID</Form.Label>
              <Form.Control type="text"
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    kiccMid: e.target.value.trim()
                  })
                }}
              />
              <Form.Text>
                KICC MID 오입력 시 결제 오류 발생할 수 있습니다.
              </Form.Text>
            </Form.Group>
            <Row>
              <Col>
                <Button type="submit" variant="secondary"
                  onClick={() => {
                    navigation(-1)
                  }}>
                  뒤로가기
                </Button>
              </Col>
              <Col>
                <Button
                  className="float-end"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBtnClicked();
                  }}>
                  가맹점 생성
                </Button>
              </Col>
            </Row>

          </Form>

        </Row>
      </Container>
    </>
  )
}

export default AdminsUsersAgenciesCreate